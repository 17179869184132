import React from 'react'
import DesignsPage from '../../templates/designs-page'
import { Link, graphql, StaticQuery } from 'gatsby'
export default ({location}) => (
    <StaticQuery
      query={graphql`
      query DesignList {
        allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {frontmatter: {templateKey: {eq: "design-detail"}}}) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                description
                featuredimage {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
                 image_1 {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
                   image_2 {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
                   image_3 {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
                   image_4 {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
                   image_5 {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
                   image_6 {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
                   image_7 {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
                  image_8 {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
              }
            }
          }
        }
      }
      
      
      `}
      render={(data) => <DesignsPage data={data} location={location}/>}
    />
  )
  
import React,{useState,useEffect} from 'react'
import Layout from '../layouts'
import GridView from '../components/assets/images/grid-view'
import ListView from '../components/assets/images/list-view'
import Helmet from 'react-helmet'
import { navigate } from 'gatsby'
import DesignViewSection from '../components/designsview'
import DesignsFilter from '../components/designs-filter/indes'
export const DesignsPageTemplate = ({data,location}) => {
  const windowGlobal = typeof window !== 'undefined' && window
  const [actvesec,setActivesec] = useState(location.pathname)
  const setCaseviewfunction = () =>{

    if(windowGlobal){
      return windowGlobal.localStorage.getItem('caseview') 
      ? 
      parseInt(windowGlobal.localStorage.getItem('caseview')) : 0
    }else{
      return 0
    }
    
  }
  const [tabview, setTabview] = useState(setCaseviewfunction)
  const scrollLisFunction = () =>{
    if(window){
      if(window.scrollY !==0){
        sessionStorage.setItem(`case_list_y`,window.scrollY)
      }    
    }
  
  }
  useEffect(()=>{
    if(window !== undefined){
      window.addEventListener('scroll',scrollLisFunction)      
    }
    return ()=>{
      window.removeEventListener('scroll',scrollLisFunction)
    }
  },[tabview])

 
  const handleTabView=(de)=>{
    if(window !== undefined){
      localStorage.setItem('caseview',de)
    }
    setTabview(de)
  }
  const casesList = data.allMarkdownRemark.edges
  return (
    <React.Fragment>
      <Helmet defer={false}>
        <title>Designs | JDSolutions</title> 
         <meta property="og:title" content={`Designs | JDSolutions`} />
        <meta property="og:type" content="cases" />
        {/* <meta property="og:image" content="http://ia.media-imdb.com/images/rock.jpg" />      */}
      </Helmet>
    <div className="row-fluid-wrapper row-depth-1 row-number-1 ">
    <div className="row-fluid ">
    <div className="span12 widget-span widget-type-cell case-page-filter cu95">
    <div className="row-fluid-wrapper row-depth-1 row-number-2 ">
    <div className="row-fluid ">
    <div className="span12 widget-span widget-type-cell wrapper">
      <div className="row-fluid-wrapper row-depth-1 row-number-3">
      <div className="row-fluid ">
        <div className="span12 widget-span widget-type-raw_html list-filter-type">

       <DesignsFilter actvesec={actvesec}/>


      
       <div className="cell-wrapper layout-widget-wrapper row-fluid desktop">
          <div className="span4 blank-grid" style={{visibility:'hidden'}}>hh</div>
            <div className="span4">
              <ul style={{marginRight:'5px'}}>
                <li className={`case-list-type ${tabview === 1 ? 'active-list':'inactive'}`}
                onClick={()=>handleTabView(1)}
                >
                  <ListView/>
                </li>
                <li className={`grid-list-type ${tabview === 0 ? 'active-list':'inactive'}`}
                 onClick={()=>handleTabView(0)}
                >
                  <GridView/>
                </li>
              </ul>
            </div>
            <div className="span4 blank-grid" style={{visibility:'hidden'}}>hh</div>
          </div>
        </div>
      </div>
      </div>
    </div>

    </div>
      </div>
      </div>
      </div>
      </div>
      <div className="row-fluid-wrapper row-depth-1 row-number-4">
      <div className="row-fluid ">
      <div className={`span12 widget-span widget-type-cell cases-lists ${tabview === 1 ? 'list' : ''}`}>
      <div className="row-fluid-wrapper row-depth-1 row-number-5">
        <div className="row-fluid ">
          <div className="span12 widget-span widget-type-cell wrapper">
            <div className="row-fluid-wrapper row-depth-1 row-number-6">
              <div className="row-fluid ">
                <div className="span12 widget-span widget-type-widget_container cases-lists-cm">
                  <span className="hs_cos_wrapper hs_cos_wrapper_widget_container hs_cos_wrapper_type_widget_container">
                    {casesList.map((dt,i)=>{
                      return <DesignViewSection data={dt} kk={i}/>
                    })}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
        </div>
      </div>
      </React.Fragment>
  )
}


const DesignsPage = ({data,location}) => {
  const [page, setPage] = useState('cases-page formal')
  return (
    <Layout page={page}>
      <DesignsPageTemplate data={data} location={location}/>
    </Layout>
  )
}



export default DesignsPage


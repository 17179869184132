// Helper functions

import { ANIMATIONS } from './constants';
const windowGlobal = typeof window !== 'undefined' && window
const injectAnimationsScript = () => {
    if(windowGlobal){
    let css = ANIMATIONS,
        head = windowGlobal.document.head || document.getElementsByTagName('head')[0],
         style = windowGlobal.document.createElement('style');

    style.type = 'text/css';
    if (style.styleSheet) {
        style.styleSheet.cssText = css;
    } else {
        style.appendChild(document.createTextNode(css));
    }

    head.appendChild(style);
}
};

export { injectAnimationsScript };